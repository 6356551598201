import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import FixedToolbar from 'components/FixedToolbar'
import MainContentLayout from 'layouts/MainContentLayout';

import { observer } from 'mobx-react'
import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts';

import RatingForm from './components/RatingForm';


//import NotificationForm from './components/NotificationForm';

const useStyles = makeStyles((theme) =>
	createStyles({
		offset: theme.mixins.toolbar,
		backToOverview: {
			backgroundColor: theme.palette.primary.light
		}
	})
);


const RatingScreen = observer((props) => {

	const classes = useStyles();
    const routerStore = useRouterStore();
    const rootStore = useRootStore();
    const { ratingsStore } = rootStore;
	
	const rating = ratingsStore.selectedObject;

    console.log("rating ", rating)

    const onBackToOverview = () => {
		routerStore.goTo("ratings")
	}

    const backButton =  <Button
		onClick={() => onBackToOverview() }
		size="small"
		variant="text">
		<ArrowBackIcon />
			Back to Overview
		</Button>

	const toolbar = <FixedToolbar back={backButton} >
		
	</FixedToolbar>


	return (
		<MainContentLayout toolbar={toolbar}>
			{rating ?
			<Grid container>
				<Grid item xs={3}>
				</Grid>
				<Grid item xs={5}>
					<Box color="primary.main" bgcolor="background.paper" margin={1} padding={1}>
                        <RatingForm instance={rating} store={ratingsStore} />
					</Box>
				</Grid>
			</Grid>: null}

			<div className={classes.offset} />
		</MainContentLayout>)
});

export default RatingScreen;

