import { DEFAULT_FIELD_VALUES, ModelFactory } from "jimbo/model/base"; 


export const ratingsPackageClassFactory = new ModelFactory();

ratingsPackageClassFactory.addClassDescriptionsData([
    {
        clazzName: "Rating",
        verboseName: "None",

        fields: [
            {
                ...DEFAULT_FIELD_VALUES,
                "name": "id",
                "auto_created": true,
                "type": "persistent.fields.CharField",
                "init_value": "null",
            },
            {
                ...DEFAULT_FIELD_VALUES,
                "name": "email",
                "type": "persistent.fields.CharField",
                "init_value": "",
            },
            {
                ...DEFAULT_FIELD_VALUES,
                "name": "personaName",
                "max_length": 512,
                "type": "persistent.fields.CharField",
            }, {
                ...DEFAULT_FIELD_VALUES,
                "name": "text",
                "max_length": 2512,
                "type": "persistent.fields.TextField",
            }, {
                ...DEFAULT_FIELD_VALUES,
                "name": "created",
                "type": "persistent.fields.DateTimeField",
            },
            , {
                ...DEFAULT_FIELD_VALUES,
                "name": "rating",
                "type": "persistent.fields.IntegerField",
            },
            {
                ...DEFAULT_FIELD_VALUES,
                "name": "publishedContentObject",
                "type": "persistent.fields.OneOf",
                "relation_clazz": "PublishedContentObjectProxy",
                "init_value": "null",
                "null": true,
            },
        ]
    },
    {
        clazzName: "PublishedContentObjectProxy",
        verboseName: "PublishedContent",
        fields: [
            {
                ...DEFAULT_FIELD_VALUES,
                "name": "id",
                "auto_created": false,
                "type": "persistent.fields.CharField",
                "init_value": "null",
            },
            {
                ...DEFAULT_FIELD_VALUES,
                "name": "name",
                "max_length": 512,
                "type": "persistent.fields.CharField",
            }
        ],

    }
]);
