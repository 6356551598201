import React from 'react';

import NotFoundScreen from 'screens/NotFoundScreen';
import SignIn from 'screens/SignIn';
import DashboardScreen from 'screens/DashboardScreen';
import ExperiencesScreen from 'screens/ExperiencesScreen';
import ExperienceScreen, { NewExperienceScreen } from 'screens/ExperienceScreen';
import StoryBlocksScreen from 'screens/StoryBlocksScreen';
import StoryBlockScreen from 'screens/StoryBlockScreen';
import FileAssetsScreen from 'screens/FileAssetsScreen';
import UploadFileAssetScreen from 'screens/UploadFileAssetScreen';
import SettingsScreen from 'screens/SettingsScreen';
import AnalyticsOverviewScreen from 'screens/AnalyticsOverviewScreen';
import NotificationsScreen from 'screens/NotificationsScreen';
import NotificationScreen from 'screens/NotificationScreen';
import RatingsScreen from 'screens/RatingsScreen ';
import RatingScreen from 'screens/RatingScreen';
import ForgotPasswordScreen from 'screens/ForgotPasswordScreen';
import ResetPasswordScreen from 'screens/ResetPasswordScreen';
import RunnableObjectsScreen from 'screens/RunnableObjectsScreen';
import RunnableObjectScreen from 'screens/RunnableObjectScreen';


export const viewMap = {
    dashboard: <DashboardScreen />,
    notFound: <NotFoundScreen />,
    signin: <SignIn />,
    experiences: <ExperiencesScreen />,
    experience: <ExperienceScreen />,
    newExperience: <NewExperienceScreen />,
    storyBlocks: <StoryBlocksScreen />,
    storyBlock: <StoryBlockScreen />,
    newStoryBlock: <StoryBlockScreen />,
    fileAssets: <FileAssetsScreen />,
    fileUpload: <UploadFileAssetScreen />,
    runnableObjects: <RunnableObjectsScreen/>,
    newRunnableObject: <RunnableObjectScreen/>,
    settings: <SettingsScreen />,
    analytics: <AnalyticsOverviewScreen />,
    notifications: <NotificationsScreen />,
    notification: <NotificationScreen />,
    ratings: <RatingsScreen/>,
    rating: <RatingScreen/>,
    newNotification: <NotificationScreen />,
    forgotPassword: <ForgotPasswordScreen />,
    resetPassword: <ResetPasswordScreen />,
};
