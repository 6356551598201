import React from 'react';

import { observer } from 'mobx-react'
import { useRouterStore } from 'mobx-state-router';
import { useRootStore } from 'contexts';

import FixedToolbar from 'components/FixedToolbar'
import MainLayoutVh from 'layouts/MainVh';

import TextSearch from 'components/search/TextSearch';
import RatingsTableVirtualized from './components/RatingsTableVirtualized'
import SearchFilterSelect from 'components/search/SearchFilterSelect';


const ratingFilterValues = [["1","1 Star"],["2","2 Stars"],["3","3 Stars"],["4","4 Stars"], ["5","5 Stars"]];


const RatingsScreen = observer(() => {

  const routerStore = useRouterStore();
  const rootStore = useRootStore();
  const {ratingsStore} = rootStore;
  
  const onSelectObject = id => {
    routerStore.goTo('rating', { params: { id } });
  }

  const toolbar = <FixedToolbar>
      <TextSearch store={ratingsStore} searchTerm="search.text" placeholder='Search on message' />
      <TextSearch store={ratingsStore} searchTerm="trail" placeholder='Search on trail name' showSearchIcon={false} />
      <SearchFilterSelect filterName="rating" filterValues={ratingFilterValues}  store={ratingsStore} label="Rating"/>
    </FixedToolbar>

  return <MainLayoutVh toolbar={toolbar} >
        <RatingsTableVirtualized  store={ratingsStore} onSelectObject={onSelectObject} />
    </MainLayoutVh>;
});

export default RatingsScreen;
