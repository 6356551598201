import * as React from 'react';

import { FormWidget, FormConfigurations } from 'jimbo/ui/Forms';
import TextField from 'jimbo/ui/TextField';
import DateTimeField from 'jimbo/ui/DateTimeField';
import SelectionChoicesField from 'jimbo/ui/SelectionChoicesField';



const ShowOneOfWidget = ({id, label, instance, fieldName, fields}) => {

    const oneOfField = instance.getField(fieldName)

    console.log("fields ", oneOfField)

    const oneOfInstance = oneOfField.value;
    const field = oneOfInstance.getField("name")
    
    console.log("field ", field)

    return <TextField id="publishedObject.name" instance={oneOfInstance} fieldName="name" label="Trailname" />
}

const ratingFormConfigurationsData = {
    // 'PublishedContentObjectProxy':{
    //         groups:  [
    //             {
    //                 label: "Trail",
    //                 fields: [
    //                     {
    //                         fieldName: "name", 
    //                         label: "Name", 
    //                         widget: TextField 
    //                     },
    //                 ]   
    //             }
    //         ]       
    //     },
    "Rating": {
        fields: [
            {
                fieldName: "publishedContentObject",
                widget: ShowOneOfWidget,
                test: "hello",
            },
            { 
                fieldName: "personaName", 
                label: "Naam ", 
                widget: TextField 
            },
            { 
                fieldName: "rating", 
                label: "Rating ", 
                widget: TextField 
            }, { 
                fieldName: "email", 
                label: "Email ", 
                widget: TextField 
            },
            {
                fieldName: "text",
                label: "Text",
                widget: TextField,
                widgetProps: { 
                    multiline: true, 
                    rows: 10 
                }                
            },
            {
                fieldName: "created",
                label: "Created on",
                widget: DateTimeField
            },

            
        ]
    }
}


const ratingFormConfigurations = new FormConfigurations(ratingFormConfigurationsData);


const RatingsForm = ({ instance, store }) => {
    return <React.Fragment>
        <FormWidget label="Notification" instance={instance} formConfigurations={ratingFormConfigurations} store={store}/>
    </React.Fragment>
}


export default RatingsForm;