
import BaseStore from "jimbo/store";

import { ratingsPackageClassFactory } from "schema/ratings";

export default class RatingsStore extends BaseStore {


    constructor({rootStore, baseApi}) {
        super({rootStore, baseApi});
    }

    fromJsonToObject = (initialData) => {
        const rating =  ratingsPackageClassFactory.createInstance("Rating", {initialData});
        return rating;
    }

    doCreateNewObject =  ({initValues}) => {
        //return ratingsPackageClassFactory.createInstance("Rating", {});
        // do nothing
    }

}